import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const Merchant = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.merchant_driving_and_monitoring}`,
    message: <FormattedMessage id="message_merchant_driving_and_monitoring" values={commonMessageValues}/>
  }];

  return (
    <TextPage locale={intl.locale} title={`${messages.merchant_driving_and_monitoring}`} textBlocks={textBlocks}/>);
};

export default Merchant;